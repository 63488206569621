@font-face {
    font-family: "Helvetica Neue Thin";
    src: url("../assets/fonts/helveticaneue-thin.woff2") format("woff2"),
        url("../assets/fonts/helveticaneue-thin.woff") format("woff");
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Helvetica Neue";
    src: url("../assets/fonts/helveticaneue.woff2") format("woff2"),
        url("../assets/fonts/helveticaneue.woff") format("woff");
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Nineteen Ninety Seven";
    src: url("../assets/fonts/Nineteen-Ninety-Seven.woff2") format("woff2"),
        url("../assets/fonts/Nineteen-Ninety-Seven.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
  font-family: "Erbos Draco 1st NBP";
  src: url("../assets/fonts/Erbos-Draco-1st-NBP.woff2") format("woff2"),
      url("../assets/fonts/Erbos-Draco-1st-NBP.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
    font-family: "Erbos Draco 1st Open NBP";
    src: url("../assets/fonts/Erbos-Draco-1st-Open-NBP.woff2") format("woff2"),
        url("../assets/fonts/Erbos-Draco-1st-Open-NBP.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

html,
body {
  font-family: "Helvetica Neue";
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "Helvetica Neue";
}