.not-support-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem;
  z-index: 2;

  .logo {
    width: 100%;
    z-index: 2;
    margin-left: -1rem;
  }

  .video-object {
    position: absolute;
    left: -30rem;
    height: 100%;
    opacity: 0.35;

    @media only screen and (min-width: 420px) {
      left: -35rem;
    }
    @media only screen and (min-width: 400px) {
      left: -40rem;
    }
    @media only screen and (min-width: 390px) {
      left: -47rem;
    }
  }
  .not-support-item {
    z-index: 2;
    margin-top: 7rem;
  }
  .social-media-buttons {
    margin-top: 3rem !important;
  }
}