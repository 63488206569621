@import "./colors";

.governance {
  padding: 1rem;
  margin-top: 0rem;

  @media (max-width: 450px) {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .nodisplay {
    display: none;
  }

  &.off {
    margin-top: 0rem;
  }

  .create {
    width: 15rem;
  }

  .grid {
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: none;
    gap: 2rem;
    margin-bottom: 2rem;

    @media (min-width: 1500px) {
      grid-template-columns: auto auto auto;
    }

    @media (max-width: 900px) {
      grid-template-columns: auto;
    }
  }

  svg {
    margin-right: 2px;
    margin-top: 0px;
  }

  .card {
    margin-top: 2rem;
    width: 28rem;

    &-body {
      padding-bottom: 0rem;
    }

    &-footer {
      padding-top: 0rem;
      padding-bottom: 0rem;
    }

    &-title {
      display: flex;
      margin-top: 1.2rem !important;
      justify-content: flex-end; //...

      a {
        color: $white;
      }

      .title-names {
        display: flex;
        flex-direction: column;
        text-align: end; //..
      }

      .title-row {
        display: flex;
        flex-direction: row;
        align-items: center;

        h5 {
          flex: 2;
          text-align: end;
        }

        .btn {
          border: none !important;
          text-align: end;
          padding-right: 0rem;
          padding-left: 10px;
        }

        .btn:focus {
          border: none;
          box-shadow: none;
        }

        .btn:hover {
          background-color: transparent;
          box-shadow: none;
        }
      }
    }

    &-text {
      margin-bottom: 1rem;
    }

    .badges-container {
      display: flex;
      margin-top: 25px;

      .badges-container2 {
        display: flex;
        flex-wrap: wrap;
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      padding: 1rem 0rem 0rem 0rem;
      margin-top: -90px;

      h5 {
        flex: 1;
        font-size: 1.1rem;
        color: $purple;
      }

      .why {        

        &-title {
          display: flex;
          flex: 1;
          flex-direction: row;
          align-items: center;
          margin-bottom: 8px;          
        }

        .accounts {
          display: flex;
          flex: 2;
          justify-content: flex-end;

          .badge {
            margin-bottom: 0rem;
          }
        }

        p {
          text-align: justify;
        }
      }

      .columns {
        display: flex;
        flex-direction: row;

        div {
          padding: 5px 0px;
        }

        @media (max-height: 850px) {
          margin-bottom: 1rem;
        }
      }

      &-col1 {
        padding-right: 10px !important;
      }

      &-col2 {
        text-align: end;
      }
    }

    @media (max-height: 850px) {
      p {
        margin-bottom: 0rem;
      }
    }
  }

  .diamond {
    border-top-right-radius: 0.5px;
    border-top-left-radius: 0.5px;
    height: 5rem;
    background-image: url("../assets/profile-header.png");
    background-repeat: repeat;
    background-position: right bottom;
    background-size: contain;
    background-color: $purple;

    @media (max-height: 850px) {
      height: 5rem;
    }
  }

  .badge.bg-light {
    //border: 1px solid $highlight;
    border-radius: 25px;
    height: 30px;
    border-color: $off-white;
    background-color: $off-white;
    color: #a440f2;
    padding: 10px;
    margin-right: 5px;
    margin-bottom: 10px;
  }

  .avatar {
    height: 130px;
    width: 130px;
    margin-top: -80px;
    border-radius: 50%;

    @media (max-height: 850px) {
      height: 120px;
      width: 120px;
    }
  }

  .blockie {
    height: 130px !important;
    width: 130px !important;
    border-radius: 50%;
    margin-top: -80px;
  }

  .continue {
    text-shadow: 0 0 4px #7940f2, 0 0 10px #fcdf49, 0 0 20px #f28040, 0 -10px 40px #c33;
    font-size: 22px;
    -webkit-animation: fire 2s infinite alternate;
    animation: fire 2s infinite alternate;
    color: #d9d7ea !important;
  }

  .discord {
    width: 15px;
    margin-right: 2px;
    margin-top: -2px;
  }

  .ethereum {
    width: 15px;
    margin-right: 2px;
    margin-top: -2px;
  }

  .ctx-icon {
    width: 17px;
    margin-right: 4px;
    margin-top: -2px;
  }

  .tally {
    width: 15px;
    margin-right: 2px;
    margin-top: -7px;
  }

  .twitter {
    height: 17px;
    margin-right: 2px;
    margin-top: -2px;
  }

  .github {
    width: 15px;
    margin-right: 2px;
    margin-top: -2px;
  }

  h5 {
    font-size: 1rem;
    color: white;
    clear: both;
  }

  .btn {
    min-height: 3.375rem;

    &:hover,
    &:active,
    &:focus {
      color: white;
    }

    @media (max-height: 850px) {
      min-height: 3rem;
    }
  }

  label {
    color: $white;
  }

  .holder-stake {
    &-content {
      display: flex;
      flex-direction: row;

      .remove {
        flex: 1;
        padding-top: 0.3rem;
        text-align: end;

        a {
          color: $orange;
          font-size: 0.8rem;
          font-family: "Nineteen Ninety Seven";
        }
      }
    }
  }

  .staker-wrapper {
    margin-top: 2rem;
    padding-left: 1rem;

    h2 {
      font-size: 1.375rem;
      margin-bottom: 1.5625rem;
    }

    h3 {
      font-size: 1rem;
    }

    .staker {
      background-color: $background;
      width: 100%;
      padding: 1rem 2.5rem;
      border: 0.5px solid $darkest;
      border-radius: .25rem;

      .table {
        color: $white;

        tr:hover {
          color: $white;
        }

        td {
          vertical-align: middle;
          a {
            font-weight: bold;
            color: $purple;
          }

          .end-date {
            font-size: 0.8rem;
            color: $light;
          }
        }

        .btn {
          width: 80%;
          min-height: 2.5rem;
          height: 2.5rem;
          padding: 0rem;
          width: 5rem;
          margin-left: auto;
          margin-right: auto;

          thead {
            .btn {
              width: 37px;
              min-width: 35px;
              height: 35px;
              border-radius: 0.25rem;
              margin-top: 5px;
              margin-left: 7px;
            }

            .rewards {
              display: flex;
              flex-direction: row;
              align-items: center;
            }
          }
        }

        .end-date {
          text-align: right;
        }
      }

      @media (max-width: 450px) {
        padding: 2rem 1rem;
      }
    }

    .stake-reward-mobile {
      .btn {
        width: 100%;
        margin-left: 0rem !important;
        margin-top: 1rem;
        height: 2.7rem;
        min-height: 2.7rem;
      }
    }
  }

  .keepers-options {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: $background;
    margin-top: 1rem;
    padding: 1rem 1.5rem;
    border-radius: 5px;
    border: 0.5px solid $darkest;

    .sort-box {
      display: flex;
      align-items: center;
      
      h6 {
        width: 100%;
      }
    }

    .dropdown {
      margin-left: 0.5rem;

      .dropdown-toggle {
        min-height: 2.5rem;
        height: 2.5rem;
        width: 13rem;
        span {
          font-size: 0.8rem;
        }
      }

      .sort-by-toggle {
        margin-right: 0.5rem;
      }
    }

    .create {
      .btn {
        min-height: 2.7rem;
      }
    }
  }

  @media (max-width: 900px) {
    display: flex;
    flex-direction: column;
    align-items: center;

    .staker-wrapper {
      width: 90%;
      padding-left: 0rem;

      h2 {
        margin-bottom: 0.2rem;
      }
    }

    .keepers-options {
      width: 90%;
    }

    .grid {
      justify-items: center;
    }

    .card {
      width: 90%;
    }

    .accordion {
      border-top: none;

      .accordion-body {
        margin-top: 1rem;
      }
    }
  }

  @media (max-width: 600px) {
    .card {
      width: 100%;
    }

    .staker-wrapper {
      width: 100%;

      .accordion {
        border-bottom: none;
      }

      .staker {
        width: 100%;
        padding-bottom: 1.5rem;

        .btn {
          min-height: 2.5rem;
          height: 2.5rem;
        }
      }
    }

    .stake-reward-mobile {
      h2 {
        margin-bottom: 0rem;
      }

      .btn {
        width: 100%;
        margin-left: 0rem !important;
        margin-top: 1rem;
      }

      .accordion-button:not(.collapsed)::after {
        padding-top: 1rem;
      }

      .accordion-body {
        margin-top: 1rem;
      }
    }

    .keepers-options {
      flex-direction: column-reverse;
      align-items: flex-start;
      width: 100%;

      .search-box {
        width: 100%;
        margin-top: 1.2rem;

        .sort-box {
          display: flex;
          justify-content: space-between;
          width: 100%;
        }

        .titles {
          width: 20%;
        }

        .dropdown {
          width: 80%;

          &-toggle {
            width: 100%;
            h6 {
              text-align: left;
            }
          }
        }
      }

      .create {
        width: 100%;
      }
    }

    .profiles {
      margin-top: 2rem;
      gap: 1rem;

      .card-footer {
        .btn {
          height: 2.5rem;
          min-height: 2.5rem;
        }
      }
    }
  }
}

.cryptkeeper-form {
  margin-top: 1rem;
  
  .avatar {
    border: 0.8px solid $green;
    position: absolute;
    height: 130px;
    width: 130px;
    margin-left: 20px;
    cursor: pointer;
  }

  .add-file-icon {
    svg {
      background: rgba(0, 0, 0, 0.175);
      border: 3px solid rgba(209, 194, 239, 0.65);
      color: rgba(209, 194, 239, 0.65);
      border-radius: 50%;
      width: 120px;
      height: 120px;
    }
  }
  
  .image-group {
    display: flex;
    padding-left: 0px;
    padding-right: 0px;
    margin-bottom: 15px;

    .col-lg-6,
    .col-md-6,
    .col-lg-8,
    .col-md-8,
    .col-md-12,
    .col-lg-12 {
      padding-left: 0px;
      padding-right: 0px;
    }

    a {
      position: absolute;
      margin-left: 35px;
    }
  }

  label {
    font-family: "Nineteen Ninety Seven";
    color: $white;

    &.max {
      float: right;

      a {
        color: $green;

        &.orange {
          color: $orange;
        }
      }
    }
  }

  .form-control {
    border: 0.5px solid $lighter;
    margin-top: 1rem;
    color: $white;
    font-size: 1.1rem;
  }

  .form-control:disabled {
    background-color: $darkest;
  }

  .form-control::placeholder {
    color: $light;
    opacity: 0.5;
  }

  .input-error {
    border: 0.5px solid $red-error;
  }

  input[type="file"] {
    background-color: $background;
    color: transparent;
    height: 100%;
    width: 85%;
    margin-left: 10px;
    border: none;
    cursor: pointer;
  }

  .custom-file-label {
    border-color: $green;
    height: 3.375rem;
    line-height: 1.5;
    display: none;
  }

  .custom-file-label::after {
    background-color: $darker;
    border-color: $pink;
    color: $white;
    font-family: "Nineteen Ninety Seven";
    font-size: 0.9rem;
    font-weight: 400;
  }

  input::file-selector-button {
    background-color: #0000 !important;
    border: none;
    color: #0000;
  }

  @media (max-width: 600px) {
    .image-group {
      margin-bottom: 2rem !important;

      .keeper-names {
        width: 30rem;
      }

      a {
        margin-left: 0rem !important;
      }
    }
  }
}