


.zoom-container {
  height: 35rem !important;
}

.chart-container {
  display: flex;
  justify-content: flex-end;
  height: 100%;

  
  .time-frame-box {
    z-index: 3;
    position: absolute;
    border: 0.5px solid $purple;
    border-radius: 5px;
    margin-top: -40px;
    margin-right: 5px;

    .btn {
      border: none;
    }
    .btn.active {
      color: $purple;
    }
    .btn:hover {
      background-color: $background;
    }

    @media only screen and (max-width: 1200px) {
      margin-top: -38px;
      margin-right: 2px;
      .btn {
        padding: 5px 10px;
      }
    }
  }

  .loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    opacity: 0.7;
    background: url("../assets/loadingchart-back.png") no-repeat center;
    background-size: cover;

    .spinner-border {
      position: absolute;
      z-index: 5;
    }
  }

  @media only screen and (max-width: 850px) {
    height: 30rem;
    margin-bottom: 5rem;
  }
  @media only screen and (max-width: 600px) {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    height: 23rem;

    .time-frame-box {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      position: relative;
      z-index: 1;
      margin-top: 3rem;
      width: 80%;
    }
  }
}

.recharts-responsive-container {
  max-height: 36.5rem;

  @media only screen and (max-width: 1300px) { 
    max-height: 37.7rem;
  }
  @media only screen and (max-width: 600px) {
    margin-left: -3rem;
  }

  .recharts-surface {
    background-color: #0d0d0d;
    padding: 0.5rem 0rem;
    @media only screen and (max-width: 600px) {
      font-size: 14px;
      width: 110%;
    }
  }
  .recharts-cartesian-grid {
    opacity: 0.3;
  }
  .recharts-text {
    tspan {
      fill: $white;
      @media only screen and (max-width: 600px) { 
        font-size: 12px;
      }
    }
  }
  .recharts-xAxis {
    margin-top: 0.5rem;
  }

  &.pepe-chart {
    .recharts-text {
      tspan {
        font-size: 10px;
      }
    }
  }
}

.recharts-tooltip-wrapper:focus-visible {
  border: 0.5px solid $purple;
  border-radius: 5px;
  outline: none;
}

.chart-custom-tooltip {
  background-color: $background;
  padding: 1rem;
  border: 0.5px solid $dark;
  border-radius: 5px;
}

.tradingview-widget-container {
  display: flex;
  align-items: flex-end;
  width: 100%;

  .pyth-icon {
    position: absolute;
    margin-left: 3.9rem;
    margin-bottom: 24px;
    z-index: 2;

    .cls-1 {
      fill: #aaa9bf !important;
    }

    @media only screen and (max-width: 1600px) and (orientation: landscape) {
      margin-bottom: 20px;
    }

    @media only screen and (max-width: 1300px) and (orientation: landscape) {
      margin-bottom: 12px;
    }
  }
}

.tradingview-widget-container-mobile {
  display: flex;
  align-items: flex-end;
  width: 100%;

  .pyth-icon {
    position: absolute;
    margin-left: 3.9rem;
    margin-bottom: 14px;
    z-index: 2;

    .cls-1 {
      fill: #aaa9bf !important;
    }

    @media only screen and (max-width: 390px) {
      margin-left: 3.8rem;
      margin-bottom: 14px;
    }
    @media only screen and (max-width: 375px) {
      margin-left: 3.8rem;
      margin-bottom: 14px;
    }
  }
}
