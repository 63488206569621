@import "./colors";


.react-datepicker-wrapper {
  .react-datepicker__calendar-icon {
    left: 82%;
    width: 2.2rem;
    height: 2.2rem;
    fill: $white;
  }

  .cryptex-date-input {
    background-color: $background;
    color: $white;
    border: 2px solid $light;
    border-radius: 5px;
    padding: 5px 0.5rem;
  }
}

.cryptex-date-input:focus-visible {
  outline: none;
}

.cryptex-calendar {
  background-color: $background !important;

  .react-datepicker {
    &__month-container {
      background-color: $background;
    }
    &__header {
      background-color: $background;
    }
    &__navigation {
      margin-top: 0.4rem;
    }
    &__current-month {
      color: $white;
      font-size: 1rem;
      margin-bottom: 0.5rem;
    }
    &__day-name {
      color: $white;
      font-size: 0.9rem;
    }
    &__day {
      color: $white;
      font-size: 0.9rem;
    }
    &__day:hover {
      background-color: $dark;
    }
    &__day--selected {
      background-color: $purple;
    }
    &__day--selected:hover {
      background-color: $purple;
    }
    &__day--disabled {
      color: $dark; 
    }
    &__day--disabled:hover {
      background-color: $background;
    }
  }
}

.btn-wrong-network {
  background-color: #e73e3e;
  border-color: #e73e3e;
  font-weight: 600;
  border-radius: 3px;
}
.btn-wrong-network:hover {
  background-color: #e73e3e;
  border-color: #e73e3e;
  border-radius: 3px;
}

.banner {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  margin: 0rem 0.8rem;
  margin-bottom: 1.5rem;
  background-color: $purple;
  border: 0.5px solid $highlight;
  border-radius: 5px;

  p {
    color: $white;
  }

  .btn-close {
    padding: 0rem;
    background-color: transparent;
    width: 1.1rem;
    height: 1.1rem;

    svg {
      fill: white;
    }
  }

  .nav-link {
    display: inline;
    margin: 0rem 5px;
    text-decoration: underline;
    text-shadow: 0px 4px 13px #AAA;
  }
}

#bg-nested-dropdown {
  border-color: $purple !important;
}

.border-primary {
  border: 0.5px solid #333;
  border-radius: 5px;
}

.border-box {
  border: 0.5px solid #333;
  border-radius: 5px;
  background-color: #0e0e19;
}

.border-box-light {
  border: 0.5px solid #333;
  border-radius: 5px;
  background-color: #1D1D2F;
}

.border-box-secondary {
  border: 0.5px solid #333;
  border-radius: 5px;
  background-color: #a540f20b;
}

.ref-button-box {
  border: 0.5px solid $dark;
  border-radius: 5px;
}
.ref-button-box:hover {
  border-color: $purple;
  -webkit-box-shadow: inset 0px 0px 58px -25px rgba(164, 64, 242, 0.48);
  -moz-box-shadow: inset 0px 0px 58px -25px rgba(164, 64, 242, 0.48);
  box-shadow: inset 0px 0px 58px -25px rgba(164, 64, 242, 0.48);
}

.notification-box {
  background: #212138;
  background: linear-gradient(180deg, #212138 20%, #1e0d32 40%, #281e31 80%);
  background: -webkit-linear-gradient(180deg, #212138 20%, #1e0d32 40%, #281e31 80%);
  background: -moz-linear-gradient(180deg, #212138 20%, #1e0d32 40%, #281e31 80%);
  border: 0.5px solid rgba(225, 33, 254, 0.2);
}

.pagination-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.pagination {
  .page-item {
    .page-link {
      background-color: $background;
      color: $white;
      border-color: $dark;
    }

    &.active {
      .page-link {
        background-color: $purple;
      }
    }
  }

}

.total-value-group {
  // margin-bottom: 0.2rem;
  width: 100%;
  .value-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 5px;
  }
  
  .value-item-desc {
    display: flex;
    align-items: center;

    .btn {
      margin-left: 0.5rem;
    }
  }

  &-detail {
    &.hide {
      display: none;
    }
    padding-left: 1rem;

    .value-item {
      h6, .number  {
        font-size: 0.9rem;
      }
    }
  }
}

.progress {
  height: 0.7rem;
  border-radius: 3px;
  &-bar {
    background-color: $purple !important;
  }
}

.vertical-divisor {
  border-right: 1px solid $dark;
}

.dropdown {
  width: 100%;
  
  .dropdown-toggle {
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 0.5px solid $dark;
    border-radius: 5px;
    width: 100%;

    .title {
      font-size: 18px;
    }
  
    .caption {
      font-size: 13px;
    }

    .market-desc {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding-left: 10px;
      font-size: 16px;
    }

    &-container {
      display: flex;
      width: 100%;
      padding-left: 0.1rem;
      padding-right: 0rem;

      .vertical-divisor {
        margin: 2px 0px;
      }

      .toggle-section {
        &.market {
          display: flex;
          align-items: center;
          width: 42%;
        }
      }

      .price-box {
        display: flex;
        align-items: center;
        margin-left: 0.25rem;
        margin-right: 0rem;
        width: 58%;
      }

      .flex-column {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
      }

      .price {
        font-size: 1.4rem;
      }
    }

    &-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 3.5rem;
      border-bottom: 0.5px solid $darkest;

      .hstack {
        align-self: auto;
      }
    }
  }

  .dropdown-toggle::after {
    display: block;
  }

  &-item {
    display: flex;
    align-items: center;
    border-bottom: 0.5px solid $darkest;

    .right {
      align-items: flex-end;
    }

    svg,
    .jpegz,
    .tcap,
    .pepe,
    .token-icon {
      margin-right: 0.5rem;
    }
  }

  &-menu-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid #281e31;
    margin: 0rem;
    padding: 0.2rem 0.2rem;

    span {
      width: 50%;
    }
  }
}

.dropdown-toggle:disabled::after {
  display: none;
}

.tokens-dropdown {
  &-toggle {
    width: 7.5rem;
    border-radius: 17px;
  }

  &-menu {
    z-index: 3;
  }

  &-item {
    height: 2.2rem;
  }
}

.search-bar {
  display: flex;
  background-color: #494866;
  border-radius: 5px;
  .search-input {
    font-size: 0.9rem;
    border: 1px solid $dark;
    background-color: #10101c;
  }
  .search-input::placeholder {
    opacity: 0.55;
  }
  .search-cancel {
    position: absolute;
    align-self: end;
    width: auto;
    color: $light2;
    font-size: 0.9rem;
    top: 8px;
  }
  .search-cancel:hover {
    background-color: transparent;
    border-color: transparent;
    box-shadow: none;
  }
}

.chart-wrapper {
  border: 0.5px solid #494866;
  border-radius: 2px;
  height: 100%;

  .btn {
    background-color: $toolbar-background;
  }
  .btn.active { 
    color: $purple;
  }
  .btn:active {
    background-color: inherit;
  }

  .accordion {
    border-top: none;
    border-bottom: none;

    .accordion-body {
      padding: 0rem;
    }
    .accordion-button {
      display: flex;
      align-items: center;
      background-color: $background;
      color: $light;
      border: 0px solid $dark;
      border-radius: 0px;
      padding: 0.35rem 0.5rem;
      font-size: 0.7rem;
    }
    .accordion-button::after {
      height: 8px;
      margin-top: 5px;
      background-image: url("../assets/chevron-up-solid.svg");
      transform: rotate(180deg);
      color: $light;
    }
    .accordion-button:not(.collapsed)::after {
      height: 8px;
      margin-right: -7px;
      margin-top: 0px;
      background-image: url("../assets/chevron-up-solid.svg");
      transform: rotate(360deg);
    }
  }

  .dropdown-timeframes {
    .dropdown-toggle:hover, .dropdown-toggle:active {
      background-color: $toolbar-background;
    }
    .dropdown-menu {
      min-height: fit-content;
      height: auto;
      max-height: 30rem;
      overflow-y: scroll;
      margin-top: -5px;
      border-radius: 2px;
    }
    .dropdown-item {
      font-size: 0.85rem;
      padding: 0.3rem 0.65rem;
    }
    .dropdown-item:hover {
      background-color: $purple;
    }
  }

}

.crypdex {
  // .dropdown-toggle:after {
    // display: none;
  // }

  .col-number {
    font-size: 1rem;
  }

  .crypdex-components {
    &.show {
      display: flex;
    }
    &.hide {
      display: none;
      
    }
    transition: display 2s ease-in-out;
  }

  .settoken-info {
    h6 {
      font-size: 1.1rem;
    }
  }
}

.markets {
  width: 100%;
  border: 0.5px solid $dark;
  border-radius: 5px 5px 0px 0px;

  &-tabs-container {
    display: flex;
    flex-direction: row;
  }

  &-stats {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0.2rem 0.5rem;
    background-color: $background;
    border-top: 0.5px solid $dark;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;

    .stats-values {
      display: flex;
      flex-direction: row;
      width: 100%;

      .number {
        padding: 0px;
      }
    }

    .stats-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      // margin-right: 20px;

      &.left {
          width: 50%;
      }
    
      &.right {
        width: 50%;
      }


      .number {
        margin-left: 7px;
        margin-right: 5px;
        font-size: 13px;
      }      
    }

    .stats-item-2 {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
}

.markets-v2 {
  .markets-info {
    display: flex;
    justify-content: space-between;

    .vertical-divisor {
      border-right: 0.5px solid #494866;
      margin: 2px 0px;
      padding-left: 1.3rem;
      height: 2.2rem;
    }
  }

  .dropdown {
    border: none;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    .dropdown-toggle {
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
      border-color: transparent;
    }

    &-menu {
      height: 37rem;
      overflow-y: scroll;
    }
  }
}

.trigger-order-box {
  margin: 0.7rem 0rem;

  .btn-trigger-add {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border: 0.5px solid $dark;// #8b6da3;
  }
  .btn-trigger-add:hover {
    background-color: transparent;
    border: 0.5px solid $purple; 
  }
  .text-purple {
    font-size: 0.95rem;
  }
}

.trigger-price-box {
  border: 0.5px solid $dark;
  border-radius: 5px;
  padding: 0.35rem;

  .trigger-price-header {
    align-items: center;
    justify-content: space-between;
  }

  .trigger-price-group {
    align-items: center;
    justify-content: space-between;
    border: 0.5px solid $light;
    border-radius: 5px;
    padding: 0rem;

    .percent-from-index {
      width: 11rem;
      padding: 0rem 0.3rem;
      border-left: 0.5px solid $dark;
      border-right: 0.5px solid $dark;
      span {
        font-size: 0.8rem;
      }
    }

    .btn-close-trigger {
      width: fit-content;
      font-size: 0.85rem;
      color: $red-error;
    }
    .btn-close-trigger:hover,
    .btn-close-trigger:active {
      background-color: transparent;
      border: none;
      font-size: 0.75rem;
    }
  }
  
  .trigger-price-group-2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .form-control {
    font-size: 1.1rem;
    border: none;
    padding: 0rem;
    padding-left: 0.5rem;
  }

  .input-error {
    border: 0.5px solid $red-error;
  }

  .dropdown {
    margin-right: 3px;
  }

  .dropdown-toggle {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 2rem;
    padding: 2px 7px;
    font-size: 0.9rem;
    border: none;
    border-radius: 0px;
    border-left: 1px solid $light;
  }
  .dropdown-toggle::after {
    font-size: 16px;  
  }
  .dropdown-menu {
    width: 3.5rem;
    min-width: 3.5rem;
    margin-left: -10px;
  }
  .dropdown-item { 
    font-size: 0.8rem;
  }
}

.limit-order {
  .trigger-price-box {
    border: none;
    padding: 0rem;
  }
  .trigger-price-group {
    border: none;
    padding: 0rem;
  }
  .trigger-price-group-2 {
    border: 0.5px solid $light;
    border-radius: 5px;
    padding: 0.3rem 0.2rem;
    padding-right: 0.4rem;
    justify-content: center;
  }
  .btn-trigger-add {
    height: 2.7rem;
    border: 0.5px solid $purple;
  }
  .btn-trigger-add:hover {
    background-color: transparent;
    border: 0.5px solid $purple;
  }
}

.btn-percents {
  padding: 0.2rem 0.3rem;
  border: 0.5px solid $dark;
  color: $lighter;
  font-size: 0.7rem;
}

.btn-percents:hover {
  filter: brightness(250%);
  background-color: transparent;
}

.btn-approve-container {
  border: 0.5px solid $lighter;
  border-radius: 5px;
  padding: 0.5rem;
  margin-top: 1rem;

  &-msg {
    padding: 1rem 0rem;
  }

  .btn-approve {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    .spinner-border {
      margin-right: 10px;
    }
  }
}

.spinner-border {
  &.text-primary {
    color: $purple !important;
  }
  &.text-secondary {
    color: $lighter !important;
  }
  &.text-danger {
    color: $red-error !important;
  }
  &.text-danger-dark {
    color: $red-dark !important;
  }
  &.small {
    --bs-spinner-width: 1.5rem;
    --bs-spinner-height: 1.5rem;
  }
  &.xs {
    --bs-spinner-width: 1.1rem;
    --bs-spinner-height: 1.1rem;
  }
  &.xxs {
    --bs-spinner-width: 0.8rem;
    --bs-spinner-height: 0.8rem;
    --bs-spinner-border-width: 0.15em;
  }
}

.alert {
  &-primary {
    background-color: $purple;
    border-color: $purple;
    color: $white;
  }

  .btn-close:hover {
    color: $white;
  }
  .btn-close:focus {
    box-shadow: none;
  }
}

.capacity-container {
  display: flex;
  flex-direction: column;
  margin: 1rem 0rem;

  .capacity-info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 0.1rem;
  }

  .capacity-values {
    display: flex;
    flex-direction: row;
    align-items: center;
    .separator {
      padding: 0px 0px;
    }
  }
}

.loading-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  .video-object {
    opacity: 0.4;
  }

  &-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    z-index: 2;
  }

  h3 {
    margin-top: 1.2rem;
  }
}

.breathing-icon {
  -webkit-animation: breathing 1s ease-out infinite normal;
  animation: breathing 1s ease-out infinite normal;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  text-align: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.info-icon {
  color: $lighter;
  margin-top: 1px;
}

.overlay-child {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.overlay-child:hover {
  cursor: pointer;
}

.tooltip {
  background-color: $black;
  border: 0.5px solid $light;
  border-radius: 5px;
  opacity: 0.95;
}

.tooltip > .tooltip-inner {
  text-align: left;
  max-width: 400px;
  width: fit-content;
  min-width: 200px;
  padding: 0.8rem;

  .tt-container {
    .tt-row {
      display: flex;
      justify-content: space-between;
      padding: 2px 0px;
    }
  }
}

.accordion {
  --bs-accordion-border-color: none;
  border-top: 1px solid $light;
  border-bottom: 1px solid $light;

  &-item {
    background-color: $background;
  }

  &-body {
    padding: 0.2rem 0.75rem;
  }

  &-header {
    border-bottom: 0.5px solid $dark;
  }

  &-button {
    background-color: $background;
    padding: 0.7rem 0.5rem;
    align-items: baseline;
  }
  &-button:not(.collapsed) {
    background-color: #141018;
  }
  &-button::after {
    background-image: url("../assets/chevron-up-solid.svg");
    transform: rotate(180deg);
  }
  &-button:not(.collapsed)::after {
    background-image: url("../assets/chevron-up-solid.svg");
    transform: rotate(360deg);
  }
}

.form-switch {
  .form-check-input {
    background-color: $dark;
    height: 1.5em;
    width: 3.5em;
    border-radius: 15px;
  }
  .form-check-input:checked {
    background-color: $purple;
  }
}

.cashback {
  padding: 2rem;

  @media only screen and (max-width: 1300px) {
    padding: 2rem 0rem;
  }

  .text-purple {
    color: $purple;
  }

  &-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1rem;

    .subtitle {
      margin: 1rem 0.5rem;
      width: 60%;
      text-align: center;

      @media only screen and (max-width: 1200px) {
        width: 80%;
      }
    }
  }

  &-countdown {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 4rem;

    &-values {
      display: flex;
      flex-direction: row;
      .value {
        display: flex;
        flex-direction: row;
        margin: 0rem 0.7rem;

        .purple {
          margin-right: 7px;
          color: $purple;
        }
      }
    }
  }

  &-user {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 3rem;
    margin-bottom: 3rem;
    width: 100%;

    .cashback-box{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border: 0.5px solid $light;
      border-radius: 5px;
      margin: 0.5rem 1rem;
      padding: 1rem 0.5rem;
      height: 7.5rem;
      width: 10rem;

      .box-value {
        display: flex;
        align-items: center;

        .red {
          color: $red-error;
        }
      }

      &.btn {
        font-size: 1.5rem;
        border: 1px solid $purple;
        font-weight: 600;
      }
    }

    .box-value {
      font-size: 1.2rem;
      @media only screen and (max-width: 1300px) {
        font-size: 1.1rem;
      }
    }
    .box-title {
      h6 {
        color: $lighter;
      }
    }
  }

  &-options {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: $background;
    border-radius: 5px;
    padding: 1rem 2rem;
    margin: 1rem 5rem;
    margin-top: 2rem;

    .options-item {
      display: flex;
      align-items: center;

      h5 {
        margin-right: 1rem;
      }
    }
  }

  &-leaderboard {
    margin: 1rem 5rem;
    padding: 1rem 2rem;
    background-color: $background;
    border-radius: 5px;

    @media only screen and (max-width: 1200px) {
      margin-left: 2rem;
      margin-right: 2rem;
    }

    &-header {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 2rem;
      
      .form-control {
        height: 2rem;
        width: 10rem;
        font-size: 1.1rem;
        margin-left: 1.1rem;
      }
    }

    .not-found {
      display: flex;
      justify-content: center;
      padding: 1rem 0rem;
    }

    .table {
      .user-cashback {
        font-size: 1.2rem;
        .color-purple {
          color: $purple;
        }
      }
      thead {
        th {
          border-bottom: 0.5px solid $purple;
          padding: 0.9rem 0.5rem;
          font-size: 1.2rem;
        }
      }
      tbody {
        td {
          border-bottom: 0.3px solid $dark;
          padding: 0.9rem 0.5rem;
        }
      }
      tr.hide {
        display: none;
      }
    }
  }

  .program-countdown {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 4rem;

    &-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 1rem 2rem;
      margin: 0rem 0.5rem;
      background-color: $background;
      border: 1px solid $light;
      border-radius: 5px;
      width: 10rem;

      .value {
        font-size: 6rem;
        color: $purple;
      }
      .caption {
        font-size: 1.5rem;
        color: $lighter;
        margin-top: -1.3rem;
      }
    }
  }

  .cell-container {
    display: flex;
    flex-direction: row;
    align-items: center;

    &.right {
      justify-content: flex-end;

      svg {
        margin-left: 5px;
      }
    }
  }
}

.airdrop-banner {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: $purple;
  border-radius: 5px;
  padding: 1rem 1.5rem;
  margin-bottom: 1rem;

  &.hide {
    display: none;
  }

  .left {
    .btn-claim {
      margin-left: 1rem;
      width: 12rem;
      border-color: $dark;
      padding: 2px;
    }
    .btn-claim:hover {
      background-color: $background;
      filter: brightness(1.3);
    }
  }

  .right {
    .btn-close2 {
      padding: 0rem;
      background-color: $purple;
  
      svg {
        fill: $white;
      }
    }
  }
}

.language-dropdown {
  width: fit-content;

  .dropdown-toggle {
    width: fit-content;
    margin-left: 0px;
    // margin-right: 5px;
    border: 1.2px solid $dark;
    border-radius: 2px;
    padding: 3.5px 4px;
  }
  .dropdown-toggle:after {
    display: none;  
  }
  .dropdown-menu {
    margin-left: -95px;
  }
  .dropdown-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 2.2rem;

   .flag {
    margin-right: 7px;
   }
  }
}

.language-modal {
  .modal-content {
    width: 20rem;
  }
  .modal-body {
    display: flex;
    flex-direction: column;

    .btn {
      display: flex;
      flex-direction: row;
      align-items: center;
      border: none;
      border-bottom: 0.5px solid $dark;
      border-radius: 0px;
      height: 3rem;
    }
    .btn-current-lang {
      background-color: $purple;
    }
    .flag {
      margin-right: 7px;
    }
  }
}

.referrals {
  display: flex;
  flex-direction: row;
  margin: 2rem 3rem;
  padding: 1rem;

  @media only screen and (max-width: 1650px) {
    margin: 2rem 1rem;
  }

  .btn-get-reflink {
    width: 15rem;
  }

  &-info {
    display: flex;
    flex-direction: column;
    margin-right: 1rem;
    border: 0.8px solid $light;
    border-radius: 5px;
    width: 50%;

    .left {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 1.5rem 1.5rem;
      
      .item {
        margin-bottom: 1rem;
      }

      .referrals-header {
        margin-bottom: 0rem;
      }
    }

    .right {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 0rem 1.5rem;
      border-radius: 5px;
    }

    .item {
      padding-bottom: 1rem;
    }

    &-links {
      display: flex;
      flex-direction: row;

      .links-url,
      .social-link {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border-radius: 5px;
        padding: 5px 10px;


      }

      .links-url {
        background-color: #494866;
        width: 25rem;

        svg {
          fill: $lighter;
        }
      }

      .social-link {
        display: flex;
        align-items: center;
        background-color: $purple;
        margin-left: 10px;

        svg {
          margin-right: 5px;
          fill: $lighter;
        }
      }
    }
  }

  &-tiers {
    display: flex;
    flex-direction: row;
    border-radius: 5px;
    margin-top: 1rem;

    .table {
      th {
        padding: 0.7rem 0.5rem;
      }

      .text-right {
        text-align: right;
      }

      thead {
        border-radius: 5px;
        background-color: #0a0a0a;
        border-bottom: 1px solid $highlight;
        ;
      }

      tr {
        border-radius: 5px;
        border-bottom: 0.5px solid $darker;
        background-color: #0a0a0a;
      }
    }

    .tier {
      display: flex;
      flex-direction: column;
      margin: 1px;

      &-volume,
      &-reward {
        display: flex;
        justify-content: flex-end;
        border-radius: 2px;

        .number {
          padding: 0px;
        }
      }
    }
  }

  &-activity {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 1rem;
    border-radius: 5px;
    width: 50%;

    .left {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      border: 0.8px solid $light;
      border-radius: 5px;
      margin-bottom: 0.5rem;
      padding: 1rem 1.5rem;

      p {
        margin: 1rem 0rem;
      }

      .amount-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        padding: 0rem 0.5rem;

        .amount {
          background-color: $darker;
          padding: 2px 8px;
          border: 0.5px solid $purple;
          border-radius: 5px;
        }
      }

      .progressbar-container {
        margin-top: 1rem;

        .progress {
          margin: 0rem 0.5rem;
        }
      }

      .progressbar-values {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 0rem -1.5rem;

        .volume-item {
          display: flex;
          flex-direction: column;
          align-items: center;

          &.item-0 {
            margin-left: 10%;
          }

          &.item-1 {
            margin-left: 21.5%;
          }

          &.item-2 {
            margin-left: 46.4%;
          }

          &.item-3 {
            margin-left: 26%;
          }

          .line {
            height: 17px;
            border-left: 1px solid $purple;
            border-right: 1px solid $purple;
          }

          .percentage {
            border-radius: 5px;
            background-color: $purple;
            padding: 3px 10px;
            font-size: 14px;
            font-weight: 600;
          }

          .value {
            margin-top: 3px;
            font-size: 14px;
            font-weight: 500;
            width: 5rem;
            text-align: center;
          }
        }

        .amount-referred {
          background-color: $darker;
          border: 0.5px solid $purple;
          padding: 1px 10px;
          border-radius: 5px;
          margin-top: 0.5rem;
        }
      }
    }

    .right {
      display: flex;
      flex-direction: column;
      border: 0.8px solid $light;
      border-radius: 5px;
      margin-top: 0.5rem;
      padding: 1rem;

      .rewards {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        height: 100%;
        margin-top: 1rem;
        padding: 0rem 1rem;

        .rewards-box {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          border: 0.5px solid $dark;
          background-color: #0f0f0f;
          border-radius: 5px;
          margin: 0.5rem 1rem;
          padding: 1rem 0.5rem;
          height: 7.5rem;
          width: 10rem;

          .box-value {
            display: flex;
            align-items: center;

            .usdc {
              width: 22px;
              height: 22px;
            }

            .red {
              color: $red-error;
            }
          }

          .number {
            font-size: 1.5rem;
          }

          &.btn {
            font-size: 1.5rem;
            border: 1px solid $purple;
            font-weight: 600;
          }
        }

        @media only screen and (max-width: 1250px) {
          padding: 0rem;

          .rewards-box {
            margin: 0.5rem 0.5rem;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 1250px) {
    margin: 2rem 1rem;
    padding: 0rem;

    &-info {
      margin-right: 0.5rem;

      .left {
        padding: 1rem;
        padding-bottom: 0.4rem;
      }
    }

    &-activity {
      margin-left: 0.5rem;
    }
  }
}

.referrals-link {
  display: flex;
  flex-direction: row;
  align-items: center;

  .invite-link {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: $darker;
    padding: 5px 10px;
    border-radius: 5px;
    width: 30rem;

    &-copy {
      border: none;
      background-color: transparent;
      padding: 0rem;
    }

    &-copy:active {
      background-color: transparent;
    }

    span {
      font-size: 1rem;
      font-weight: 500;
    }

    svg {
      fill: white;
    }
  }

  .share-links {
    padding-left: 0.5rem;

    .react-share__ShareButton {
      margin: 0rem 3px;
    }
  }

  @media only screen and (max-width: 1250px) {
    flex-direction: column;
    align-items: flex-start;
    // width: 100%;

    .invite-link {
      width: 100%;
    }

    .share-links {
      padding-left: 0rem;
      margin-top: 0.7rem;
    }
  }
}

.referral-accept {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 2rem;
  border: 1px solid #a540f29a;
  border-radius: 5px;
  // background-color: #a540f21c;
  margin-bottom: 2rem;

  .btn-close-container{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: -0.5rem;
    margin-right: -2rem;
    width: 100%;

    .btn-close2 {
      border: none;
      padding: 0rem;
  
      svg {
        fill: $white;
      }
    }
    .btn-close2:hover {
      background-color: transparent;
    }
  }

  .info-container {
    display: flex;
    flex-direction: row;

    .info-box {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 35rem;
      background-color: #a540f22c;
      border: 0.5px solid #53535b;
      border-radius: 5px;
      margin: 1.5rem 0rem;

      .img-container {
        display: flex;
        flex-direction: row;
        border-radius: 50%;
        padding: 1rem;

        img {
          width: 2.5rem;
        }
      }

      p {
        text-align: center;
        width: 80%;

        span {
          font-weight: 800;
          color: $purple;
        }
      }
    }
  }

  .btn-container {
    .btn {
      background-color: $purple;
      width: 15rem;
    }
  }

}

.referral-create {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: $purple;
  border-radius: 5px;
  padding: 1rem 1.5rem;
  margin-bottom: 1rem;

  &.hide {
    display: none;
  }

  .btn-get-reflink {
    margin-left: 1rem;
    width: 12rem;
    border-color: $dark;
    padding: 2px;
  }
  .btn-get-reflink:hover {
    background-color: $background;
    filter: brightness(1.3);
  }

  .left {
    display: flex;
    flex-direction: row;
    align-items: center;

    .img-container {
      margin-right: 1rem;
      img {
        width: 1.8rem;
      }
    }

    .referral-info {
      display: flex;
      flex-direction: row;
      align-items: center;

      .info {
        margin-right: 10px;
      }
    }

    span {
      font-size: 1.1rem;
      color: $white;
      font-weight: 900;
    }
  }

  .right {
    display: flex;
    flex-direction: row;
    align-items: center;

    .btn-close2 {
      padding: 0rem;
      background-color: $purple;
      svg {
        fill: $white;
      }
    }
  }
}

.warning-price-update {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  padding: 0.3rem;
  border: 0.5px solid $orange;
  border-radius: 5px;

  p {
    font-size: 0.75rem;
  }
}

.warning-orders {
  padding: 0.35rem 0.5rem;
  border: 0.5px solid $orange;
  border-radius: 5px;

  p {
    font-size: 0.85rem;
  }
}

.mobile-detail-container {
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 0.5rem;

  .mobile-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    background-color: $mobile-item-background;
    padding: 13px 10px;
    border-radius: 5px;

    .title {
      font-size: 1rem;
      color: $light2;
    }

    .number {
      padding: 0px;
    }

    &.left {
      align-items: flex-end;
    }
  }
}

@-webkit-keyframes breathing {
  0% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }

  10% {
    -webkit-transform: scale(0.92);
    transform: scale(0.92);
  }

  20% {
    -webkit-transform: scale(0.94);
    transform: scale(0.94);
  }

  30% {
    -webkit-transform: scale(0.96);
    transform: scale(0.96);
  }

  40% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
  }

  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  60% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
  }

  70% {
    -webkit-transform: scale(0.96);
    transform: scale(0.96);
  }

  80% {
    -webkit-transform: scale(0.94);
    transform: scale(0.94);
  }

  90% {
    -webkit-transform: scale(0.92);
    transform: scale(0.92);
  }

  100% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
}

@keyframes breathing {
  0% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }

  10% {
    -webkit-transform: scale(0.92);
    -ms-transform: scale(0.92);
    transform: scale(0.92);
  }

  20% {
    -webkit-transform: scale(0.94);
    -ms-transform: scale(0.94);
    transform: scale(0.94);
  }

  30% {
    -webkit-transform: scale(0.96);
    -ms-transform: scale(0.96);
    transform: scale(0.96);
  }

  40% {
    -webkit-transform: scale(0.98);
    -ms-transform: scale(0.98);
    transform: scale(0.98);
  }

  50% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }

  60% {
    -webkit-transform: scale(0.98);
    -ms-transform: scale(0.98);
    transform: scale(0.98);
  }

  70% {
    -webkit-transform: scale(0.96);
    -ms-transform: scale(0.96);
    transform: scale(0.96);
  }

  80% {
    -webkit-transform: scale(0.94);
    -ms-transform: scale(0.94);
    transform: scale(0.94);
  }

  80% {
    -webkit-transform: scale(0.92);
    -ms-transform: scale(0.92);
    transform: scale(0.92);
  }

  100% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }
}


@media only screen and (min-width: 851px) and (max-width: 1399px),
  (min-height: 600px) and (max-height: 790px) and (orientation: landscape) 
{
  .markets-v2 {
    .dropdown {
      .dropdown-toggle {
        padding-top: 7px;
        padding-bottom: 7px;

        .title {
          font-size: 15px;
        }

        .caption {
          font-size: 12px;  
        }

        &-container {
          .toggle-section {
            &.market {
              width: 45%;
            }
          }

          .price-box {
            margin-left: 0.25rem;
            margin-right: 0rem;
            width: 55%;
          }

          .price {
            font-size: 1.4rem;
          }
        }

        .market {
          img {
            width: 31px;
            height: 31px;
          }
        }
      }

      &-menu {
        height: 34rem;
        overflow-y: scroll;
      }
    }

    .dropdown-menu::-webkit-scrollbar {
      width: 6px;
    }
  }

  .total-value-group {
    .value-item {
      margin-bottom: 1px;
    }
  }

  .warning-price-update {
    margin-top: 0.5rem;
    margin-bottom: 0.3rem;
  }
}

// LiFi
// .css-1oy62i0-MuiButtonBase-root-MuiButton-root {
.wrapper > .MuiButton-containedPrimary {
  // position: fixed !important;
  // flex-direction: row !important;
  top: 75% !important;
  background-color: $background !important;
  border: 0.5px solid $light2 !important;
  border-right-color: $background !important;

  p {
    display: none !important;
  }

  svg {
    fill: $off-white;
    width: 28px;
    margin-bottom: 5px;
    // transform: rotate(90deg) !important;
    // margin-top: 3px;
  }
}


@media only screen and (min-width: 1700px) {
  .markets-v2 {
    .dropdown {
      .dropdown-toggle {
        &-container {
          .toggle-section {
            &.market {
              width: 30%;
            }
          }
        
          .price-box {
            margin-left: 0.25rem;
            margin-right: 0rem;
            width: 70%;
          }
        }
      }
    }
  }

  .markets-stats {
    .stats-item {
      &.left {
        width: 40%;
      }
      &.right {
        width: 60%;
        justify-content: center;
        margin-right: 10%;
      }
    }
  }
}