$primary: #1d1d3c;
$highlight: #a440f2;
$neon-highlight: rgba(223, 33, 254, 0.5);
$purple: #A440F2;
$dark: #494866;
$darker: #212138;
$darkest: #281e31;
$light: #9795b0;
$light2: #aaa9bf;
$lighter: #e1e0ec;
$white: #ffffff;
$off-white: #f5f5f5;
$pink: #e440f2;
$orange: #eb7413;
$blue: #0fd9ff;
$dark-blue: #2620fb;
$green: #03c620;
$bright-green: #4dd70c;
$yellow: rgb(252, 223, 73);
$background: #0a0a0a;
$black: #000;
$red-error: #ff0c0c;
$red-dark: #c90404fe;
$mobile-item-background: #161617;
// 101010
$toolbar-background: #131722;
