@import "./colors";

.modal {
  color: $white;
  overflow-y: hidden;
  background-color: #00000098;

  &-dialog {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    max-width: 100%;
    background-color: #00000098;
    margin-top: 0rem;
    padding-top: 4rem;
  }

  &-content {
    background-color: $background;
    border: 0.5px solid $darkest;
    margin-bottom: 5rem;
    width: 52rem;
    max-width: 55rem;
  }

  &-sm {
    .modal-content {
      width: 35rem;  
    }
  }

  &-lg {
    .modal-content {
      width: 45rem;
    }
  }

  &-xl {
    .modal-content {
      width: 52rem;
    }
  }

  &-header {
    border-bottom: 0.5px solid $dark;

    .btn-close {
      filter: invert(1);
      opacity: 0.9;
    }
    .btn-close:focus {
      box-shadow: none;
    }
  }

  &-footer {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    flex-shrink: unset;
    border-top: 0.5px solid $dark;
  }
}

.modal-tos {
  padding-left: 0px !important;
}

.modal-edit-position {
  .modal-header {
    border-bottom: none;
  }

  .modal-content {
    width: 52rem;
    max-width: 55rem;

    .edit-left {
      width: 50%;
      padding: 0.6rem;
      margin-top: -0.5rem;
      border: 0.5px solid $light2;
      border-radius: 5px;      
    }

    .edit-right {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 50%;
      margin-top: -0.5rem;
      padding: 0.2rem 1.5rem;

      .summary {
        &-box {
          padding: 0.5rem 1rem;
          border: 0.5px solid $dark;
          border-radius: 5px;

          &.changes {
            margin-top: 1rem;
            margin-bottom: 1rem;
          }
        }

        &-item {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          margin-top: 0.7rem;

          &-2 {
            display: flex;
            align-items: center;

            svg,
            .jpegz,
            .tcap,
            .perpe {
              margin-right: 5px;
            }
          }

          .value-change {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            width: 70%;

            &-number {
              display: flex;
              flex-direction: row;
              align-items: center;
            }

            .number {
              font-size: 14px;
            }
          }
        }
      }
    }
  }

  &.confirm {
    .modal-content {
      width: 30rem;
    }
  }

  .modal-body {
    display: flex;
    flex-direction: row;
  }

  .inputs-container {
    margin-top: 2rem;
    margin-bottom: 0rem;
  }  

  .btn-edit-position, .btn-position {
    width: 100%;
  }

  .leverage-group {
    margin-top: 1rem;

    .leverage-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .leverage-values {
      display: flex;
      justify-content: space-between;
      padding: 0px 3px;
      width: 100%;
    }
  }

  .liquidation {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
  }

  .maintainance {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 1rem;

    &-right {
      display: flex;
      align-items: center;

      svg {
        margin-right: 7px;
      }
    }
  }

  .confirm-position {
    display: flex;
    flex-direction: column;

    .change-info {
      margin-top: 1rem;
      
      .info-item {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .title {
          width: 30%;
        }
        .value-change {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          align-items: center;
          width: 70%;
          
          .number {
            margin: 0rem 10px;
          }
        }
      }
    }
  }
};

.modal-close-position {
  .modal-content {
    width: 30rem;
  }

  .close-info {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    .summary-item {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin: 0.5rem 0rem;
    }
  }  

  .modal-footer {
    .btn {
      width: 100%;
    }
  }
};

.modal-approve-position {
  .modal-content {
    width: 30rem;
  }
  .modal-footer {
    .btn {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
  }
}

.modal-edit-order {
  .modal-content {
    width: 27rem;
  }

  .modal-footer {
    .btn {
      width: 100%;
    }
  }

  .position-data {
    padding: 0.5rem;
    border: 0.5px solid $dark;
    border-radius: 5px;
    // justify-content: space-between;

    .left {
      align-items: center;
      width: 33%;

      .info {
        justify-content: center;
      }
      .caption {
        font-size: 0.85rem;
      }
    }

    .right {
      align-items: flex-end;
    }
  }

  .trigger-price-group, .trigger-amount-group {
    border: 0.7px solid $light;
    border-radius: 5px;
    padding-right: 0.5rem;

    .form-control {
      font-size: 1.2rem;
      border: none;
    }

    svg {
      width: 28px;
      height: 28px;
    }
  }

  .order-trigger-changes {
    padding: 0.4rem 0.5rem;
    border: 0.5px solid $dark;
    border-radius: 5px;
  }
}

.modal-share-position {
  .modal-header {
    border-bottom: none;
    padding-top: 1rem;
    padding-bottom: 0rem;
  }
  
  .modal-content {
    width: 25rem;
  }

  .modal-body {
    padding: 1rem 2rem;

    .image-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin-bottom: 1rem;
      width: 100%;
  
      .share-image {        
        width: 100%;
        border-radius: 5px;
      }
    }

    .position-box {
      // border: 0.5px solid $dark;
      // border-radius: 5px;
      background-color: $background;

      &-header {
        padding: 1rem;
        border: 0.5px solid $dark;
        border-radius: 5px;
        background-color: #0f0f10;
    
        .market {
          display: flex;
          flex-direction: row;
          align-items: center;
    
          &-info {
            margin-left: 10px;
    
            .postion-direction {
              display: flex;
              flex-direction: row;
              align-items: center;
            }
    
            .status {
              display: flex;
              flex-direction: row;
              align-items: center;
              margin-left: 7px;
    
              .text-red,
              .text-green,
              .text-grey,
              .spinner-border {
                margin-left: 7px;
              }
            }
          }
        }
      }

      &-body {
        display: grid;
        grid-template-columns: auto auto;
        margin-top: 0.5rem;
        // padding: 1rem;
        gap: 0.5rem;

        .box-item {
          display: flex;
          flex-direction: column;
          background-color: #0f0f10;
          border: 0.5px solid $dark;
          padding: 13px 10px;
          border-radius: 5px;

          &.algin-right {
            align-items: flex-end;
          }
        }
      }
    }
    .btn-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-top: 1rem;

      .react-share__ShareButton {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border: 1px solid $purple !important;
        border-radius: 5px;
        padding: 0.5rem !important;
        width: 100%;

        svg {
          margin-right: 3px;
        }
      }
    }
  }
}

.modal-tos {
  .modal-content {
    width: 25rem;
  }

  .close-info {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    .summary-item {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin: 0.5rem 0rem;
    }
  }  

  .modal-footer {
    .btn {
      width: 100%;
    }
  }
};

.modal-approve-operator {
  .modal-content {
    width: 30rem;
    .modal-body {
        padding: 1.75rem 1rem;
    }
    .modal-footer {
      .btn {
        width: 100%;
      }
    }
  }
}

.lifi-modal {
  .modal-dialog {
    height: fit-content;
  }
  .modal-content {
    height: fit-content;
    width: fit-content;
  }
  .modal-header {
    border-bottom: none;
  }
  .modal-body {
    padding-top: 0px;
    min-height: 420px;
    height: auto;
    max-height: 610px;
  }

  #widget-app-expanded-container-\:r17\: {
    min-height: 400px;
    height: fit-content;
    max-height: 530px !important;
  }

  #widget-app-expanded-container-\:r7\: {
    max-height: 530px !important;
    min-height: 400px;
    height: 420px;
  }
  #widget-scrollable-container-\:r7\: {
    height: 530px !important;
  }

  // routes box
  .css-1cm5944-MuiScopedCssBaseline-root,
  .css-1q4ykaj {
    max-height: 530px !important;
  }
}

.push-modal {
  .modal-content {
    width: 27rem;
  }

  .modal-body {
    .nav-tabs {
      .nav-item {
        width: 9rem;
      }
      .nav-link {
        width: 100%;
      }
    }
  }
}

.swap-modal {
  .modal-content {
    width: 30rem;
  }
}

@media only screen and (max-width: 850px) {
  .modal-edit-position {
    .modal-content {
      .edit-left {
        .exchange {
          margin-top: 0rem;
          margin-left: 0rem;
          margin-right: 0rem;
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .modal-edit-position {
    .modal-body {
      display: flex;
      flex-direction: column;
      overflow-y: scroll;
    }
    .modal-dialog {
      padding: 0rem 0.5rem;
      padding-top: 2.5rem;
      margin: 0rem;
    }
    .modal-content {
      border: none;
      overflow-y: scroll;
      height: 90%;

      .edit-left {
        width: 100%;
        padding: 0.5rem;
        .exchange {
          padding: 0rem;
          border: none;
        }
      }
      .edit-right {
        width: 100%;
        padding: 1rem 0rem;
        margin-top: 0.5rem;

        h6 {
          font-size: 1.1rem;
        }

        .summary-item {
          .title {
            font-size: 1.05rem;
          }
          .value-change {
            .number {
              font-size: 15px;
            }
          }
        }

        .position-changes {
          margin-bottom: 2rem;
        }

        .btn-edit-position, .btn {
          margin-top: 2rem;
        }
      }
    }

    .leverage-group {
      .leverage-header {
        align-items: center;
      }
    }

    &.confirm {
      .modal-content {
        height: auto;
      }
    }
  }

  .modal-update-index-vault {
    .modal-body {
      padding: 0.8rem 0.5rem;
    }
    .index-vault-form {
      flex-direction: column;
    }
    .index-form {
      width: 100% !important;
      padding: 0rem !important;
    }
    .update-vault-info {
      .info-top {
        padding: 0.5rem !important;
      }
      .info-bottom {
        padding: 0rem 0.5rem !important;
      }
    }
  }

  .modal-tos {
    padding-left: 0px !important;
    background-color: #00000098;
  }
}