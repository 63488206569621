@import "./colors";
@import "./fonts";

.horizontal-slider {
  position: relative;
  width: 100%;
  margin: 7px 0px;
  height: 7px;
  border: 1px solid transparent;
  border-radius: 5px;
  background-color: transparent;

  .slider-track {
    top: 0px;    
    height: 7px;
    border: 0px solid transparent;
    border-radius: 5px;
    background: linear-gradient(90deg, rgba(77, 215, 12, 1) 21%, rgba(255, 92, 0, 1) 58%, rgba(255, 14, 14, 1) 93%);

    &.slider-track-1 {
      left: 0px !important;
      width: 100%;
      background: linear-gradient(90deg, rgba(77, 215, 12, 1) 21%, rgba(255, 92, 0, 1) 58%, rgba(255, 14, 14, 1) 93%);
    }
  }

  .slider-thumb {
    top: -3px;
    width: 13px;
    height: 13px;
    line-height: 0px;
    font-size: 0.9em;
    text-align: center;
    background-color: $white;
    color: transparent;
    cursor: pointer;
    border: 2px solid $white;
    border-radius: 50%;
    box-sizing: border-box;
  }
  .slider-thumb:focus-visible {
    outline: none;
  }

  .slider-mark {
    display: none;
    margin: 0px calc(19px);
    bottom: calc(50% - 6px);
    width: 8px;
    height: 8px;
    border: 2px solid rgb(0, 0, 0);
    background-color: rgb(255, 255, 255);
    cursor: pointer;
    border-radius: 50%;
  }

  @media only screen and (max-width: 850px) {
    margin-top: 17px;
    margin-bottom: 11px;
    height: 14px;

    .slider-thumb {
      top: -8px;
      width: 22px;
      height: 22px;
    }
  }
}
