.spot {
  .css-cgrgzr-MuiList-root {
    height: 32rem !important;
  }

  .css-1useozw-MuiContainer-root {
    .css-0 {
      height: 320px !important;
    }
  }

  // routes box
  .css-1cm5944-MuiScopedCssBaseline-root, .css-1q4ykaj {
    max-height: 35rem !important;
  }
}

/* .css-i4bv87-MuiSvgIcon-root, .css-19mquw-MuiSvgIcon-root {
  width: 1.5em !important;
  height: 1.5em !important;
}

.css-1vg38f0-MuiSvgIcon-root {
  width: 1em !important;
  height: 1em !important;
} */

// support button and info
// .css-121j4ty-MuiPaper-root-MuiCard-root, .css-13pmxen

.css-sp12pv-MuiContainer-root, .css-1pu40dd {
  .css-1yuhvjn,
  .css-13pmxen,
  .css-waep35-MuiTypography-root,
  .css-12i7gz1 {
    display: none !important;
  }
}

/* 
.css-1htkaz {
  .css-1yuhvjn,
  .css-13pmxen {
    display: inherit !important;
  }
} */